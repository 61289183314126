$main-black: #3d3d3d;
$main-blue: #007dff;

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Helvetica', sans-serif;
 // color: $main-black;
}

.MuiTable-root {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
}

div {
  font-size: 16px;
}

#root {
  overflow: hidden;
}

.disable-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.disable-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.simple-link-blue {
  text-decoration: none;
  font-size: 16px;
  color: $main-blue;
  transition: 0.25s all ease-in-out;
  &:hover {
    transition: 0.25s all ease-in-out;
    color: #130aff;
  }
  &:active {
    transition: 0.25s all ease-in-out;
    color: #1d4eff;
    opacity: 0.5;
  }
  &:visited {
    transition: 0.25s all ease-in-out;
    color: $main-blue;
  }
}

.simple-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: $main-black;

  &:hover {
    text-decoration: none;
    color: $main-black;
  }

  &:visited {
    text-decoration: none;
    color: $main-black;
  }

  &:focus {
    text-decoration: none;
    color: $main-black;
  }

  &:active {
    text-decoration: none;
    color: $main-black;
  }
}

.normal-list {
  margin-top: 10px;
  li {
    font-size: 14px;
    font-weight: 400;
    color: $main-black;
    position: relative;
    padding-left: 12px;
    &::after {
      content: ' ';
      position: absolute;
      display: block;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: $main-black;
      left: 0;
      top: 50%;
      margin-top: -1.5px;
    }
  }
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.screen-size {
    height: 100vh;
    width: 100vw;
  }
}

.img-in-box {
  width: 100%;
}

.status-indicator {
  width: 9px;
  min-width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 1px lightgray solid;
  margin-right: 5px;

  &.yellow {
    background: yellow;
  }
}

.header-logo {
  margin-right: 20px;
  cursor: pointer;
}

.subtitle-header {
  padding: 15px;
  border-bottom: 1px solid lightgrey;
}

.overlay-box {
  position: relative;
  vertical-align: top;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    vertical-align: top;
  }

  &-caption {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -10px;
    left: 0;
    color: white;
    text-align: center;
    z-index: 2;
    font-weight: 500;
    font-size: 18px;
  }

  &-inner {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      vertical-align: top;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      background: rgba(0, 0, 0, 0.3);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
}

.upload-picture {
  min-width: 100%;
  width: 100%;
}

.text-align-right {
  text-align: right;
}

.link {
  text-decoration: none;
  color: #1976d2;
  transition: 0.25s all ease-in-out;
  font-size: 14px;

  &.text-align-right {
    display: block;
  }

  &:hover {
    color: #0026d2;
    transition: 0.25s all ease-in-out;
  }

  &:visited {
    color: #1976d2;
  }
}

//#img-mapper {
//  img {
//    width: 1140px;
//    max-width: 100%;
//  }
//}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 9999;
}
#root {
  overflow: hidden;
  position: relative;
}
.lds-ripple {
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -40px;
  left: 50%;
  margin-left: -40px;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.carousel .control-prev.control-arrow::before {
  border-right: 8px solid rgba(0, 0, 0, 0.6) !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid rgba(0, 0, 0, 0.6) !important;
}

.docs-file-uploader-wrapper label,
.docs-file-uploader-wrapper .upload-picture {
  min-width: 0;
  width: 100%;
  max-width: 100%;
}

.lds-dual-ring.blue {
  width: 21px;
  height: 21px;
  top: 50%;
  margin-top: -12px;
  right: 10px;
  left: auto;
  &::after {
    width: 17px;
    margin: 0;
    height: 17px;
    border: 2px solid;
    border-color: $main-blue transparent $main-blue transparent;
  }
}

.lds-dual-ring.black {
  width: 25px;
  height: 25px;
  top: 50%;
  margin-top: -17px;
  right: 10px;
  left: auto;
  &::after {
    width: 21px;
    margin: 0;
    height: 21px;
    border: 2px solid;
    border-color: rgba(0, 0, 0, 0.5) transparent rgba(0, 0, 0, 0.5) transparent;
  }
}

.burger-line-wrapper {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -9px;

  &.active {
    .burger-line {
      background: $main-blue;
      transition: 0.25s all ease-in-out;
    }
  }
}

.burger-line {
  width: 18px;
  height: 4.71px;
  background: $main-black;
  transition: 0.25s all ease-in-out;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }
}

div.footer-tab {
  display: none !important;
}

#zsiq_float {
  opacity: 0;
  bottom: 41px !important;
  right: 39px !important;
}
.upload-picture.lkjDVC {
  height: 56px !important;
}

.upload-picture {
  font-size: 0 !important;
  svg {
    display: none !important;
  }
}
.upload-picture.lkjDVC .dmajdp > span {
  font-size: 12px !important;
}

area {
  cursor: pointer;
}

.image-mapper-wrapper.hover {
  .img-mapper-canvas {
    opacity: 1;
    transition: 0.25s all ease-in-out;
  }
}

.img-mapper-canvas {
  transition: 0.25s all ease-in-out;
  cursor: pointer;
  area {
    cursor: pointer;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  margin-top: -40px;
  left: 50%;
  margin-left: -30px;
  z-index: 90;
}
.lds-dual-ring::after {
  content: ' ';
  display: block;
  width: 54px;
  height: 54px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid;
  border-color: rgba(255, 255, 255, 0.6) transparent rgba(255, 255, 255, 0.6) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.preloader-overlay-lds {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
  }
}

.color-radio-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.color-radio-button-image {
  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 2px;
    height: 7px;
    background: white;
    top: 50%;
    margin-top: -2px;
    left: 50%;
    margin-left: -2.5px;
    transform-origin: 0% 100%;
    transform: rotate(-45deg);
    opacity: 0;
    transition: 0.3s all ease-in-out;
    z-index: 10;
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 2px;
    height: 15px;
    background: white;
    left: 50%;
    margin-left: -2.5px;
    top: 50%;
    margin-top: -10px;
    transform-origin: 0% 100%;
    transform: rotate(35deg);
    opacity: 0;
    transition: 0.3s all ease-in-out;
    z-index: 10;
  }

  &.active {
    &::after {
      opacity: 1;
      transition: 0.3s all ease-in-out;
    }
    &::before {
      opacity: 1;
      transition: 0.3s all ease-in-out;
    }
  }
}

.color-radio-button {
  width: calc(100% / 4);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;

  &-white {
    .color-radio-button-box-inner {
      background: none !important;
    }
    .color-radio-button-box {
      &::after {
        background: black;
      }
      &::before {
        background: black;
      }
    }
  }

  &.active {
    .color-radio-button-box-inner {
      width: 24px;
      height: 24px;
      transition: 0.3s all ease-in-out;
    }

    .color-radio-button-box {
      &::after {
        opacity: 1;
        transition: 0.3s all ease-in-out;
      }
      &::before {
        opacity: 1;
        transition: 0.3s all ease-in-out;
      }
    }
  }

  &-box {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    margin-right: 10px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 2px;
      height: 7px;
      background: white;
      top: 50%;
      margin-top: -2px;
      left: 50%;
      margin-left: -2px;
      transform-origin: 0% 100%;
      transform: rotate(-45deg);
      opacity: 0;
      transition: 0.3s all ease-in-out;
    }

    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 2px;
      height: 15px;
      background: white;
      left: 50%;
      margin-left: -2px;
      top: 50%;
      margin-top: -10px;
      transform-origin: 0% 100%;
      transform: rotate(35deg);
      opacity: 0;
      transition: 0.3s all ease-in-out;
    }

    &-inner {
      width: 17px;
      height: 17px;
      border-radius: 6px;
      transition: 0.3s all ease-in-out;
    }
  }
}

@font-face {
  font-family: 'Helvetica';
  src: url('Helvetica-Light.eot');
  src:
    url('Helvetica-Light.eot?#iefix') format('embedded-opentype'),
    url('Helvetica-Light.woff2') format('woff2'),
    url('Helvetica-Light.woff') format('woff'),
    url('Helvetica-Light.ttf') format('truetype'),
    url('Helvetica-Light.svg#Helvetica-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('Helvetica-Bold.eot');
  src:
    url('Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
    url('Helvetica-Bold.woff2') format('woff2'),
    url('Helvetica-Bold.woff') format('woff'),
    url('Helvetica-Bold.ttf') format('truetype'),
    url('Helvetica-Bold.svg#Helvetica-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('Helvetica.eot');
  src:
    url('Helvetica.eot?#iefix') format('embedded-opentype'),
    url('Helvetica.woff2') format('woff2'),
    url('Helvetica.woff') format('woff'),
    url('Helvetica.ttf') format('truetype'),
    url('Helvetica.svg#Helvetica') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('HelveticaNeueBold.eot');
  src:
    url('HelveticaNeueBold.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeueBold.woff2') format('woff2'),
    url('HelveticaNeueBold.woff') format('woff'),
    url('HelveticaNeueBold.ttf') format('truetype'),
    url('HelveticaNeueBold.svg#HelveticaNeueBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/*@font-face {*/
/*    font-family: 'HelveticaNeue';*/
/*    src: url('HelveticaNeueLight.eot');*/
/*    src: url('HelveticaNeueLight.eot?#iefix') format('embedded-opentype'),*/
/*    url('HelveticaNeueLight.woff2') format('woff2'),*/
/*    url('HelveticaNeueLight.woff') format('woff'),*/
/*    url('HelveticaNeueLight.ttf') format('truetype'),*/
/*    url('HelveticaNeueLight.svg#HelveticaNeueLight') format('svg');*/
/*    font-weight: 300;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

@font-face {
  font-family: 'HelveticaNeue';
  src: url('HelveticaNeueMedium.eot');
  src:
    url('HelveticaNeueMedium.eot?#iefix') format('embedded-opentype'),
    url('HelveticaNeueMedium.woff2') format('woff2'),
    url('HelveticaNeueMedium.woff') format('woff'),
    url('HelveticaNeueMedium.ttf') format('truetype'),
    url('HelveticaNeueMedium.svg#HelveticaNeueMedium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/*@font-face {*/
/*    font-family: 'Helvetica Neue';*/
/*    src: url('HelveticaNeue-UltraLight.eot');*/
/*    src: url('HelveticaNeue-UltraLight.eot?#iefix') format('embedded-opentype'),*/
/*    url('HelveticaNeue-UltraLight.woff2') format('woff2'),*/
/*    url('HelveticaNeue-UltraLight.woff') format('woff'),*/
/*    url('HelveticaNeue-UltraLight.ttf') format('truetype'),*/
/*    url('HelveticaNeue-UltraLight.svg#HelveticaNeue-UltraLight') format('svg');*/
/*    font-weight: 100;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

@font-face {
  font-family: 'Inter';
  src: url('Inter-Bold.eot');
  src:
    url('Inter-Bold.eot?#iefix') format('embedded-opentype'),
    url('Inter-Bold.woff2') format('woff2'),
    url('Inter-Bold.woff') format('woff'),
    url('Inter-Bold.ttf') format('truetype'),
    url('Inter-Bold.svg#Inter-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

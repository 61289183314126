.upload-picture.lkjDVC {
  width: 100% !important;
  min-width: 100% !important;
  font-size: 16px;
  height: 100px;

  svg {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 28%;
  }

  .dmajdp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & > span {
      font-size: 16px;
    }
    .file-types {
      margin-left: 20px;
    }
  }
}

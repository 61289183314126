.car-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 9px;
  padding-top: 9px;
  border-bottom: 1px solid #e1e1e1;

  &:last-child {
    border-bottom: none;
  }
}

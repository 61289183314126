$main-black: #3d3d3d;
$main-blue: #007dff;
$main-green: #4ded6d;
$bg-light-blue: #f7fafb;

* {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

.main-screen-wrapper {
  min-height: 696px;
  background: url('../../assests/img/promo/bg.png') no-repeat;
  background-size: 100% auto;
  position: relative;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;
}

.button-link {
  text-decoration: none;
  color: $main-black;
  transition: 0.25s all ease-in-out;

  &:hover {
    color: $main-blue;
    transition: 0.25s all ease-in-out;
  }

  &:visited {
    color: $main-black;
    transition: 0.25s all ease-in-out;
  }
  &:active {
    color: $main-blue;
    transition: 0.25s all ease-in-out;
  }
}

.link-button-contained {
  color: white;
  text-decoration: none;

  &:visited {
    color: white;
  }
  &:active {
    color: white;
  }
  &:focus {
    color: white;
  }
  &:hover {
    color: white;
  }
}

.header {
  &-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 31px;
    padding-bottom: 31px;
  }

  &-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    li {
      margin-right: 62px;
      font-family: 'Helvetica', sans-serif;
      font-weight: 400;
      font-size: 16px;
    }
  }

  &-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 45px;
  }
}

.main-screen {
  position: relative;

  &-wrapper {
    overflow: hidden;
  }

  &-inner {
    margin-top: 52px;
    position: relative;
  }
}

.bg-main {
  bottom: 0;
  right: 0;

  &-apple {
    position: absolute;
    height: 622px;
    width: 887px;
    background: url('../../assests/img/promo/bg-apple.png') no-repeat 0 0;
    opacity: 0;
    transition: 1s all ease-in-out;

    &.active {
      opacity: 1;
      transition: 1s all ease-in-out;
    }
  }

  &-green {
    position: absolute;
    height: 515px;
    width: 1362px;
    background: url('../../assests/img/promo/bg-main-green.png') no-repeat 0 0;
    opacity: 0;
    transition: 1s all ease-in-out;

    &.active {
      opacity: 1;
      transition: 1s all ease-in-out;
    }
  }
  &-red {
    position: absolute;
    height: 515px;
    width: 1362px;
    background: url('../../assests/img/promo/bg-main-red.png') no-repeat 0 0;
    opacity: 0;
    transition: 1s all ease-in-out;

    &.active {
      opacity: 1;
      transition: 1s all ease-in-out;
    }
  }
  &-black {
    position: absolute;
    height: 515px;
    width: 1362px;
    background: url('../../assests/img/promo/bg-main-black.png') no-repeat 0 0;
    opacity: 0;
    transition: 1s all ease-in-out;

    &.active {
      opacity: 1;
      transition: 1s all ease-in-out;
    }
  }
  &-white {
    position: absolute;
    height: 515px;
    width: 1362px;
    background: url('../../assests/img/promo/bg-main-white.png') no-repeat 0 0;
    opacity: 0;
    transition: 1s all ease-in-out;

    &.active {
      opacity: 1;
      transition: 1s all ease-in-out;
    }
  }
}

.services {
  margin-top: 65px;

  &-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
  }

  &-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: calc((100% - 36px) / 3);
    margin-bottom: 22px;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 6px;
    margin-right: 18px;
    height: 134px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    &:nth-child(3n) {
      margin-right: 0;
    }

    &-icon {
      margin-right: 30px;
    }

    &-title {
      font-family: 'Helvetica', sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: $main-black;
      margin-bottom: 6px;
    }

    &-description {
      font-family: 'Helvetica', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: $main-black;
      line-height: 18.4px;
    }
  }
}

.eco {
  margin-top: 70px;
  padding-top: 100px;
  padding-bottom: 100px;
  background:
    linear-gradient(142deg, rgba(247, 255, 235, 0.66) 0%, rgba(181, 216, 130, 0.08) 100%),
    url('../../assests/img/promo/eco-bg.png') bottom right no-repeat;
  background-position: 106% 175%;

  &-item {
    margin-bottom: 30px;
    display: flex;

    &-count {
      font-family: 'Helvetica', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      color: $main-green;
      text-transform: uppercase;
      margin-right: 6px;
    }

    &-content {
      font-family: 'Helvetica', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      color: $main-black;
    }
  }
}

.center-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.auction {
  margin-top: 80px;

  &-item {
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;

    &-markers {
      position: absolute;
      top: 15px;
      left: 11px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &-timer {
      position: absolute;
      top: 15px;
      right: 11px;
    }

    &-verified {
      margin-right: 11px;

      &.small-desktop {
        width: 150px;
        img {
          width: 100%;
        }
      }
    }

    &-main {
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 28px;
    }

    &-title {
      color: $main-black;
      font-weight: 700;
      font-family: 'Helvetica', sans-serif;
      font-size: 18px;
      margin-bottom: 17px;
    }

    &-image {
      img {
        width: 100%;
      }
    }

    &-options {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 25px;
    }

    &-option {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &-value {
        margin-left: 8px;
        color: $main-black;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Helvetica', sans-serif;
      }
    }
  }
}

.steps {
  background: $bg-light-blue;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 80px;

  &-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 85px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: -5px;
      width: 100%;
      height: 1px;
      background: $main-black;
    }
  }

  &-item {
    position: relative;
    padding-top: 26px;

    &-title {
      font-weight: 700;
      font-family: 'Helvetica', sans-serif;
      color: $main-black;
      margin-bottom: 7px;
      font-size: 18px;
    }

    &-desc {
      font-family: 'Helvetica', sans-serif;
      color: $main-black;
      font-size: 16px;
      line-height: 18.4px;
    }

    &:nth-child(1) {
      width: 218px;
    }

    &:nth-child(2) {
      width: 193px;
    }

    &:nth-child(3) {
      width: 188px;
    }

    &:nth-child(4) {
      width: 197px;
    }

    &-image {
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
    }
  }
}

.about {
  margin-top: 80px;
  vertical-align: top;

  &-image {
    width: 100%;
    vertical-align: top;
    img {
      vertical-align: top;
    }
  }
}

.slick-slide {
  & > div {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.faq {
  margin-top: 50px;
  padding-top: 30px;
  padding-bottom: 40px;
  background: $bg-light-blue;
}

.accordion-wrapper {
  margin-bottom: 30px;
}

.accordion-item {
  border-bottom: 2px solid #e7e7e7;

  &.open {
    .accordion-item-title {
      &::after {
        transform: rotate(45deg);
        transition: 0.25s all ease-in-out;
      }
    }
    .accordion-item-content {
      transform: scaleY(1);
      position: relative;
      transition: 0.35s all ease-in-out;
      opacity: 1;
      height: auto;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &-title {
    cursor: pointer;
    font-family: 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    padding-top: 16px;
    padding-bottom: 16px;
    color: $main-black;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 14px;
      height: 14px;
      background: url('../../assests/img/promo/icon-plus.png') no-repeat;
      right: 5px;
      top: 50%;
      margin-top: -7px;
      transform: rotate(0deg);
      transition: 0.25s all ease-in-out;
    }
  }

  &-content {
    color: $main-black;
    font-family: 'Helvetica', sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    border-top: #e7e7e7 2px solid;
    padding-left: 24px;

    padding-right: 24px;

    transform: scaleY(0);
    opacity: 0;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: 0.35s all ease-in-out;
  }

  &:first-child {
    border-top: #e7e7e7 2px solid;
  }
}

.flex-end-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.get-started {
  margin-top: 80px;
  overflow: hidden;

  &-image {
    height: 570px;
    position: relative;

    img {
      position: absolute;
      top: 24px;
      right: 0;
      height: 546px;
      border-radius: 0 15px 15px 0;
    }
  }

  &-form {
    margin-top: 10px;
  }
}

.upload-btn {
  img {
    transition: 0.25s all ease-in-out;
  }
  &:hover {
    img {
      filter: brightness(250%);
      transition: 0.25s all ease-in-out;
    }
  }
}

.footer {
  padding-top: 47px;
  padding-bottom: 47px;
  background: $bg-light-blue;
  margin-top: 80px;

  &-main {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 30px;

    &-side-menu {
      position: relative;
      padding-left: 11px;

      &-item {
        display: block;
        text-decoration: none;
        margin-bottom: 15px;
        cursor: pointer;
        color: $main-black;
        transition: 0.25s all ease-in-out;

        &:hover {
          color: $main-blue;
          transition: 0.25s all ease-in-out;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &::after {
        content: '';
        position: absolute;
        left: -10px;
        top: 7px;
        height: calc(100% - 14px);
        width: 3px;
        background: $main-blue;
      }
    }

    &-menu {
      position: relative;
      padding-left: 11px;
      margin-right: 60px;

      &-title {
        font-family: 'Helvetica', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: $main-black;
        margin-bottom: 15px;
      }

      &-list {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 550px;

        li {
          width: 25%;
          font-family: 'Helvetica', sans-serif;
          font-size: 16px;
          font-weight: 300;
          margin-bottom: 15px;

          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8) {
            margin-bottom: 0;
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        left: -10px;
        top: 7px;
        height: calc(100% - 14px);
        width: 3px;
        background: $main-blue;
      }
    }
  }

  &-question {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-button {
      width: 364px;
      height: 48px;
      color: $main-blue;
      background: #f7fafb;
      border: 1px solid $main-blue;
      font-family: 'Helvetica', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      cursor: pointer;
      transition: 0.25s all ease-in-out;

      &:focus {
        outline: none;
      }

      &:hover {
        color: white;
        background: $main-blue;
        transition: 0.25s all ease-in-out;
      }
    }
  }

  &-rights {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 97px;

    &-text {
      font-family: 'Helvetica', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #b8b8b8;
    }
  }
}

.header-burger {
  display: none;
}

@keyframes header-burger-1 {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(7.2px) rotate(0deg);
  }
  100% {
    transform: translateY(7.2px) rotate(45deg);
  }
}

@keyframes header-burger-2 {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-7.2px) rotate(0deg);
  }
  100% {
    transform: translateY(-7.2px) rotate(-45deg);
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  transform-origin: 50% 50%;
  pointer-events: none;
  transform: scale(0);
  transition: 0.5s all ease-in-out;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.open {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1);
    transition: 0.5s all ease-in-out;
  }

  .header-mobile-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    li {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 767px) {
  .faq {
    display: none;
  }

  html,
  .slick-list,
  .slick-slider,
  div {
    scroll-behavior: auto !important;
  }

  .eco {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 24px;
    padding-bottom: 200px;
    background-position: 100% 150%;

    &-wrapper {
      display: block;
    }

    &-item {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .container {
    padding-left: 16.5px;
    padding-right: 16.5px;
  }

  .header {
    &-menu {
      display: none;
    }

    &-inner {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &-actions {
      display: none;
    }

    &-burger {
      display: block;
      width: 32px;
      height: 20px;
      position: relative;
      z-index: 101;

      &.active {
        .header-burger-line {
          background: $main-black;
          transition: 0.25s all ease-in-out;
          opacity: 0.8;
          &:nth-child(1) {
            animation: header-burger-1 0.5s linear forwards;
          }
          &:nth-child(3) {
            animation: header-burger-2 0.5s linear forwards;
          }
          &:nth-child(2) {
            opacity: 0;
            transition: 0.25s all ease-in-out;
          }
        }
      }

      &-line {
        height: 3.2px;
        background: $main-blue;
        margin-bottom: 4px;
        transform-origin: 50% 50%;
      }
    }
  }

  .main-screen {
    &-inner {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-wrapper {
      background-size: auto 100%;
      background-position: center center;
      min-height: 567px;
      position: relative;
    }
  }

  .bg-main {
    width: 350px;
    height: 168px;
    background-size: auto 100%;
    background-position: left center;
    top: auto;
    bottom: 0;

    &.bg-main-apple {
      background-position: right bottom;
    }
  }

  .services {
    margin-top: 32px;

    &-content {
      margin-bottom: 16px;
    }

    &-item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 8px;
      height: auto;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .auction {
    margin-top: 60px;

    .slide {
      &:first-child {
        .auction-item {
          margin-left: 8px;
        }
      }
      &:last-child {
        .auction-item {
          margin-right: 8px;
        }
      }
    }

    .container {
      padding: 0;
    }

    &-item {
      margin-bottom: 30px;
      margin-right: 4px;
      margin-left: 4px;
    }
  }

  .steps {
    margin-top: 60px;

    &-wrapper {
      display: block;
      margin-top: 30px;
      padding-left: 40px;

      &::after {
        width: 1px;
        height: calc(100% - 10px);
        left: 0;
        top: 10px;
      }
    }

    &-item {
      width: 100% !important;
      padding-top: 0;
      margin-bottom: 20px;
      position: relative;

      &::after {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $main-blue;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 18px;
        font-weight: 700;
        font-family: 'Helvetica', sans-serif;
        top: 5px;
        left: -50px;
        z-index: 2;
      }

      &:nth-child(1) {
        &::after {
          content: '1';
        }
      }

      &:nth-child(2) {
        &::after {
          content: '2';
        }
      }

      &:nth-child(3) {
        &::after {
          content: '3';
        }
      }

      &:last-child {
        margin-bottom: 0;
        &::after {
          content: '4';
        }
      }

      &-image {
        position: static;
        margin-bottom: 15px;

        img {
          width: auto;
        }
      }

      &-title {
        margin-bottom: 8px;
      }
    }
  }

  .about {
    margin-top: 60px;

    &-image {
      img {
        width: 100%;
      }
    }
  }

  .footer {
    margin-top: 60px;
    padding-top: 25px;
    padding-bottom: 25px;

    &-logo {
      margin-right: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 35px;
    }

    &-main {
      display: flex;
      justify-content: center;

      &-menu {
        margin-right: 0;
        margin-left: 15px;
        padding-right: 15px;
        width: 100%;

        &-list {
          width: 100%;
          justify-content: flex-start;
        }
      }
    }

    &-question {
      display: none;
    }
  }

  .footer-main-menu-list li {
    width: calc(100% / 3);
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px !important;
  }

  .footer-main-menu-title {
    font-weight: 700;
  }

  .footer-main-side-menu {
    padding-left: 0;
    padding-right: 0;
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-item {
      margin-bottom: 0;
      margin-right: 24px;
      font-weight: 700;
      position: relative;
      padding-left: 7px;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -7.5px;
        height: 15px;
        width: 3px;
        background: $main-blue;
      }

      &:last-child {
        margin-right: 0;
      }
    }
    &::after {
      display: none;
    }
  }

  .footer-rights {
    margin-top: 24px;
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  .header {
    &-menu {
      li {
        margin-right: 5px;

        a {
          font-size: 14px;
        }

        &:last-child {
          margin-right: 8px;
        }
      }
    }
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-screen {
    &-wrapper {
      position: relative;
      background-size: auto 100%;
    }
  }

  .bg-main {
    width: 736px;
    height: 275px;
    background-size: 100% auto;
    background-position: left bottom;

    &.bg-main-apple {
      height: 622px;
    }
  }

  .services {
    &-item {
      width: calc(50% - 8px);
      margin-right: 0;
      margin-bottom: 16px;

      &:nth-child(2n - 1) {
        margin-right: 16px;
      }
    }
  }

  .steps {
    &-item {
      &:nth-child(1) {
        width: 134px;
      }

      &:nth-child(2) {
        width: 172px;
      }

      &:nth-child(3) {
        width: 125px;
      }

      &:nth-child(4) {
        width: 177px;
      }

      &-title {
        font-size: 16px;
      }

      &-desc {
        font-size: 14px;
        line-height: normal;
      }
    }
  }

  .about {
    &-image {
      //height: 100%;
      //position: absolute;
      //width: calc(100% - 16px);
      //top: 0;
      //left: 0;
      overflow: hidden;
      border-radius: 15px;
      img {
        width: 100%;
        //height: 100%;
        //width: auto;
        //position: absolute;
        //top: 0;
        //left: -50%;
      }
    }
  }

  .footer-main {
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
  }

  .footer-logo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-bottom: 40px;
  }

  .footer-main-menu-list li {
    font-size: 14px;
  }

  .footer-main-menu-list {
    width: 460px;
  }

  .footer-main-menu {
    margin-right: 20px;
  }

  .footer-rights {
    margin-top: 32px;
  }

  .auction-item-image {
    img {
    }
  }
}

@media (min-width: 768px) and (max-width: 1250px) {
  .about-image {
    img {
      height: 300px;
      width: auto;
    }
  }

  .header {
    &-actions {
      gap: 7px;
    }
  }
}

@media (min-width: 950px) and (max-width: 1300px) {
  .about-image img {
    left: -15%;
  }
}

.sign-up-sub-field .MuiFormControl-root {
  padding-right: 0 !important;
}

.double-switch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 65px;
  background: #e1e1e1;
  height: 14px;
  border-radius: 20px;
  position: relative;
  transition: 0.25s all ease-in-out;

  &-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &-caption {
    cursor: pointer;
    font-size: 14px;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }

  &.negative {
    background: #ec8db0;
    .double-switch-indicator {
      right: calc(100% - 11.5px);
      background: #d81b60;
      transition: 0.25s all ease-in-out;
    }
  }

  &.positive {
    background: #8ded80;
    transition: 0.25s all ease-in-out;

    .double-switch-indicator {
      right: 11.5px;
      background: #2b902f;
      transition: 0.25s all ease-in-out;
    }
  }

  &-indicator {
    height: 23px;
    width: 23px;
    border-radius: 50%;
    background: #cccccc;
    position: absolute;
    top: -4.5px;
    right: 50%;
    margin-right: -11.5px;
    transition: 0.25s all ease-in-out;
  }

  &-left {
    position: absolute;
    z-index: 2;
    width: calc(100% / 3);
    left: 0;
    height: 160%;
    top: -30%;
    cursor: pointer;
  }

  &-right {
    position: absolute;
    z-index: 2;
    width: calc(100% / 3);
    right: 0;
    height: 160%;
    top: -30%;
    cursor: pointer;
  }

  &-center {
    position: absolute;
    z-index: 2;
    width: calc(100% / 3);
    left: calc(100% / 3);
    height: 160%;
    top: -30%;
    cursor: pointer;
  }
}
